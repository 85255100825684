import React from "react"
import Layout from "../components/styling/layout"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"

const StyledPrivacyContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
`

const PrivacyPage = ({ data }) => (
  <Layout>
    <StyledPrivacyContainer>
      <MDXRenderer>
        {data.privacy.nodes[0].body}
      </MDXRenderer>
    </StyledPrivacyContainer>
  </Layout>
)

export default PrivacyPage

export const pageQuery = graphql`
  {
    privacy: allMdx(filter: {fileAbsolutePath: {regex: "/(privacy)/"}}) {
      nodes {
        body
      }
    }
  }
`
